<template>
	<div>
		<div class="form">
			<van-form validate-first>
				<van-field label="头像" class="avatar">
					<template #input>
						<div class="row cc avatar-header">
							<van-uploader :after-read="afterRead" :max-count="1">
								<div class="avatar-upload"><van-image class="img" fit="cover" :src="avatar" /></div>
							</van-uploader>
						</div>
					</template>
				</van-field>
				<van-field label="昵称">
					<template #input>
						<div class="van-cell__value van-field__value" @click="showName = true">
							<div class="van-field__body">
								<input :value="userInfo.nickname" type="text" readonly="readonly" placeholder="填写昵称" class="van-field__control" />
								<van-image class="arrow" :src="require('@/assets/arrow-right-gray.png')" fit="cover" />
							</div>
						</div>
					</template>
				</van-field>
				<van-field label="性别">
					<template #input>
						<div class="van-cell__value van-field__value" @click="showGender = true">
							<div class="van-field__body">
								<input :value="sexText" type="text" readonly="readonly" placeholder="性别选择" class="van-field__control" />
								<van-image class="arrow" :src="require('@/assets/arrow-right-gray.png')" fit="cover" />
							</div>
						</div>
					</template>
				</van-field>
				<van-field label="城市">
					<template #input>
						<div class="van-cell__value van-field__value" @click="showArea = true">
							<div class="van-field__body">
								<input v-model="userInfo.city" type="text" readonly="readonly" placeholder="去设置" class="van-field__control" />
								<van-image class="arrow" :src="require('@/assets/arrow-right-gray.png')" fit="cover" />
							</div>
						</div>
					</template>
				</van-field>
			</van-form>
		</div>
		<van-popup v-model="showArea" position="bottom" round><van-area :area-list="areaList" @confirm="onAreaConfirm" @cancel="showArea = false" /></van-popup>
		<van-popup v-model="showGender" position="bottom" round><van-picker show-toolbar :columns="columns" @confirm="onGenderConfirm" @cancel="showGender = false" /></van-popup>
		<van-popup v-model="showName" position="bottom" round>
			<div class="col edit-username">
				<div class="title">修改昵称</div>
				<van-field placeholder="请输入昵称" @focus="onBlurInput()" v-model="userInfo.nickname" maxlength="15" />
				<div class="row cb bottom">
					<span class="tips">15字以内，不可含有特殊字符与emoji</span>
					<div class="row cc btn" @click="confirmName()">提交</div>
				</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
import { Toast } from 'vant';
import { Area, Uploader } from 'vant';
import { areaList } from '@vant/area-data';
export default {
	data() {
		return {
			showName: false,
			showGender: false,
			showArea: false,
			columns: [
				{
					id: 1,
					text: '男'
				},
				{
					id: 2,
					text: '女'
				}
			],
			areaList,
			userInfo: {
				avatar: '',
				nickname: '',
				sex: '',
				city: ''
			}
		};
	},
	components: { vanArea: Area, vanUploader: Uploader },
	computed: {
		avatar() {
			return this.$store.getters.oss(this.userInfo.avatar);
		},
		sexText() {
			let index = this.columns.findIndex(e => e.id == this.userInfo.sex);
			if (index !== -1) {
				return this.columns[index].text;
			} else {
				return '';
			}
		}
	},
	async created() {
		let { data } = await this.$service.post('user_info/detail');
		this.userInfo.avatar = data.avatar;
		this.userInfo.nickname = data.nickname;
		this.userInfo.sex = data.sex;
		this.userInfo.city = data.city;
	},
	methods: {
		afterRead(file) {
			file.status = 'uploading';
			file.message = '上传中...';
			this.$service.upload(file.file).then(res => {
				file.status = 'done';
				file.message = '上传成功';
				this.userInfo.avatar = this.$store.getters.oss(res.data.url);
				this.submit();
			});
		},
		async submit() {
			Toast.loading({
				message: '',
				forbidClick: true,
				loadingType: 'spinner'
			});
			await this.$service.post('user_info/edit', this.userInfo);
			Toast.clear();
			Toast.success({
				message: '资料修改成功',
				duration: 800
			});
			// setTimeout(() => {
			// 	this.$router.go(-1);
			// }, 800);
		},
		confirmName() {
			this.showName = false;
			this.submit();
		},
		onGenderConfirm(e) {
			this.userInfo.sex = e.id;
			this.showGender = false;
			this.submit();
		},
		onBlurInput() {
			window.scroll(0, 0);
		},
		onAreaConfirm(values) {
			this.userInfo.city = values
				.filter(item => !!item)
				.map(item => item.name)
				.join('/');
			this.showArea = false;
			this.submit();
		}
	}
};
</script>

<style scoped lang="less">
@import '~@/styles/form.less';
@import './edit.less';
</style>
